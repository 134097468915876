/*jslint vars: true, plusplus: true, devel: true, nomen: true, indent: 4, maxerr: 50 */
(function(){
	'use strict';

jQuery( document ).ready(function() {

    // Smooth scrolling

    jQuery(function() {
      jQuery('a[href*="#"]:not([href="#"])').click(function() {

        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {

          var target = jQuery(this.hash);
          target = target.length ? target : jQuery('[name=' + this.hash.slice(1) +']');
          if (target.length) {
            jQuery('html, body').animate({
              scrollTop: target.offset().top,
              easing: "easeInCirc"
            }, 700);
            return false;
          }
        }
      });
    });

    // Mobile navigation
    jQuery(".mobile-nav .icon-menu, #site-nav .icon-close").click(function(){
    	jQuery("#site-nav").stop().toggleClass("mobile-nav-open");
        jQuery("body").toggleClass("overflow-hidden");
    });


    // // // Sub navigation only for training academy

    jQuery(".blog-2 .menu-item-has-children").hover(function(){	
    	if (jQuery(this).is(".show-submenu")) {
    		jQuery(".menu-item-has-children").removeClass("show-submenu");
            jQuery(".close-sub-menu").hide();
    	} else {
    		jQuery(".menu-item-has-children").removeClass("show-submenu");
    		jQuery(this).addClass("show-submenu");
            jQuery(".close-sub-menu").delay( 300 ).fadeIn();
    	}
    });


    jQuery(".close-sub-menu").click(function(){   
        jQuery(".menu-item-has-children").removeClass("show-submenu");
        jQuery(".close-sub-menu").hide();
    });

    

   // Toggle Service spheres

    jQuery(".block.service-sphere-block article:nth-child(1)").addClass("active-sphere");

    jQuery(".block.service-sphere-block article").click(function(){ 
        if (jQuery(this).is(".active-sphere")) {
            jQuery(".block.service-sphere-block article").removeClass("active-sphere");
        } else {
            jQuery(".block.service-sphere-block article").removeClass("active-sphere");
            jQuery(this).addClass("active-sphere");
        }
    });


    // Interactive World map

    jQuery("#map-worldwide li").click(function(){

    	if (jQuery(this).is(".show-detail")) {
    		jQuery("#map-worldwide li").removeClass("show-detail");
    	} else {
    		jQuery("#map-worldwide li").removeClass("show-detail");
    		jQuery(this).addClass("show-detail");
    	}
    });


    // Toggle Read more blocks

    jQuery(".block-read-more .link-read-more, .block-read-more-all .link-read-more").click(function(){
        jQuery(this).parent().toggleClass("show");
        
        jQuery(".block-read-more.show .link-read-more").text("Read less");
        jQuery(".block-read-more:not(.show) .link-read-more").text("Read more");

        jQuery(".block-read-more-all.show .link-read-more").text("Hide specialist fields");
        jQuery(".block-read-more-all:not(.show) .link-read-more").text("Show specialist fields");
    });


    

});

})(); // END OF USE STRICT FUNCTION